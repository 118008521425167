export const SHOWMODAL = 'SHOWMODAL';
export const HIDEMODAL = 'HIDEMODAL';
export const TOGGLELOADING = 'TOGGLELOADING';
interface ModalAction {
  type: string;
  message?: ModalMessage;
  icon?: 'success' | 'caution' | 'gender';
}
interface ModalMessage {
  isOpen?: boolean;
  isShowClose?: boolean;
  loading?: boolean;
  title: string;
  content: React.ReactNode;
  btnText: string;
  height?: string;
  onConfirm?: (event: Event) => void;
  onClose?: () => void;
}
export type ModalActionType = ModalAction;
export type ModalMessageType = ModalMessage;

import { ActionFromReducer, combineReducers, createStore, StoreEnhancer } from 'redux';
import { viewerReducer } from './user';
import { modalReducer } from './modal';
import { loginReducer } from './login';
import { countryReducer } from './country';
const rootReducer = combineReducers({
  viewer: viewerReducer,
  modal: modalReducer,
  login: loginReducer,
  countries: countryReducer,
});

type WindowWithDevTools = Window & {
  __REDUX_DEVTOOLS_EXTENSION__: () => StoreEnhancer<unknown, {}>;
};

const isReduxDevtoolsExtenstionExist = (arg: any): arg is WindowWithDevTools => {
  return '__REDUX_DEVTOOLS_EXTENSION__' in arg;
};

export { rootReducer };
export type RootState = ReturnType<typeof rootReducer>;
export type Actions = ActionFromReducer<typeof rootReducer>;

export default createStore(
  rootReducer,
  {},
  isReduxDevtoolsExtenstionExist(global) ? global.__REDUX_DEVTOOLS_EXTENSION__() : undefined
);

import 'react-virtualized/styles.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
import 'rc-tabs/assets/index.css';
import 'react-image-lightbox/style.css';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import 'react-day-picker/lib/style.css';
import 'rc-time-picker/assets/index.css';
import 'viewerjs/dist/viewer.css';
import '~public/styles/index.scss';
import '~public/styles/global.css';
import '~public/styles/booking-process.css';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import * as Sentry from '@sentry/browser';
import ApolloClient from 'apollo-boost';
import moment from 'moment';
import { NextPage } from 'next';
import App, { AppContext, AppProps } from 'next/app';
import getConfig from 'next/config';
import React from 'react';
import TagManager from 'react-gtm-module';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { AuthProvider } from '~components/auth/provider';
import LanguageTipsProvider from '~components/language-tips/language-tips-provider';
import ThemeProviderProvider from '~components/theme-provider';
import ToastProvider from '~components/toast/toast-provider';
import { Authorization } from '~constants/cookie-properties';
import { appWithTranslation, i18n } from '~i18n';
import store from '~store';
import withApolloClient from '~utils/with-apollo';

const { publicRuntimeConfig } = getConfig();
const env = publicRuntimeConfig.ENV;
const gtmId = publicRuntimeConfig.GTM;

interface Props {
  apollo: ApolloClient<{}>;
  token: string;
}

let environment = null;
switch (env) {
  case 'prod':
    environment = 'prod';
    break;
  case 'stage':
    environment = 'stage';
    break;
  case 'uat1':
    environment = 'uat-1';
    break;
  case 'uat2':
    environment = 'uat-2';
    break;
  default:
    break;
}
if (environment && process.browser) {
  Sentry.init({
    dsn: 'https://c41382fe44e94b3a99c582a742c150a6@o24149.ingest.sentry.io/5210430',
    environment,
  });
}

class StudentPortalApp extends App<AppProps & Props> {
  static async getInitialProps({ Component, ctx }: AppContext) {
    let token = null;
    if (ctx.req?.headers.cookie) {
      const decodedCookie = decodeURIComponent(ctx.req?.headers.cookie);
      const splitCookie = decodedCookie.split(';');
      const name = `${Authorization}=`;
      for (let i = 0; i < splitCookie.length; i += 1) {
        let cookieItem = splitCookie[i];
        while (cookieItem.charAt(0) === ' ') {
          cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
          token = cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }

    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { token: token, pageProps };
  }

  componentDidMount() {
    moment.locale(i18n?.language || 'en-gb');
    Modal.setAppElement('#__next');
    const tagManagerArgs = {
      gtmId,
      allowLinker: window.location?.search.includes('?_ga'),
    };
    TagManager.initialize(tagManagerArgs);
  }
  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  render() {
    const { Component, pageProps, token } = this.props;
    return (
      <Provider store={store}>
        <ThemeProviderProvider>
          <ToastProvider>
            <LanguageTipsProvider>
              <AuthProvider token={token}>
                <Component {...pageProps} />
              </AuthProvider>
            </LanguageTipsProvider>
          </ToastProvider>
        </ThemeProviderProvider>
      </Provider>
    );
  }
}

interface Props {
  ua: string;
  [propName: string]: any;
}
const agentWrapper = (Comp: NextPage) =>
  class extends React.Component<Props> {
    static async getInitialProps(args: any) {
      return {
        ua: args.ctx.req ? args.ctx.req.headers['user-agent'] : window.navigator.userAgent,
        ...(Comp.getInitialProps ? await Comp.getInitialProps(args) : null),
      };
    }

    render() {
      const { ua, ...props } = this.props;
      return (
        <UserAgentProvider ua={ua}>
          <Comp {...props} />
        </UserAgentProvider>
      );
    }
  };

export default withApolloClient(agentWrapper(appWithTranslation(StudentPortalApp)));

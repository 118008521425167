export const USER_UNAUTHORIZED = 'USER_UNAUTHORIZED';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const TOO_MANY_BOOKINGS = 'TOO_MANY_BOOKINGS';
export const BED_ALREADY_OCCUPIED = 'BED_ALREADY_OCCUPIED';
export const INVALID_ACADEMIC_YEAR = 'INVALID_ACADEMIC_YEAR';
export const INVALID_TENANCY_OPTION_ID = 'INVALID_TENANCY_OPTION_ID';
export const INVALID_ROOM_TYPE_ID = 'INVALID_ROOM_TYPE_ID';
export const INSTALLMENT_PLAN_CHANGED = 'INSTALLMENT_PLAN_CHANGED';
export const BOOKING_EXPIRED = 'BOOKING_EXPIRED';
export const STUDENT_USER_NOT_FOUND = 'STUDENT_USER_NOT_FOUND';
export const INVALID_BOOKING_ID = 'INVALID_BOOKING_ID';
export const INVALID_NODE_ID = 'INVALID_NODE_ID';

import { ModalActionType, ModalMessageType, SHOWMODAL, HIDEMODAL, TOGGLELOADING } from './types';

export const displaySuccessToast = (message: ModalMessageType): ModalActionType => ({
  type: SHOWMODAL,
  message,
  icon: 'success',
});

export const displayWarnToast = (message: ModalMessageType): ModalActionType => ({
  type: SHOWMODAL,
  message,
  icon: 'caution',
});

export const displayGenderToast = (message: ModalMessageType): ModalActionType => ({
  type: SHOWMODAL,
  message,
  icon: 'gender',
});

export const modalDisappear = (): ModalActionType => ({
  type: HIDEMODAL,
});

export const toggleModalLoading = (loading: boolean): any => ({
  type: TOGGLELOADING,
  message: {
    loading: loading,
  },
});

import { SHOWMODAL, HIDEMODAL, ModalActionType, TOGGLELOADING } from './types';

const initialState = false as any;

export function modalReducer(state = initialState, action: ModalActionType) {
  switch (action.type) {
    case SHOWMODAL:
      return {
        ...action,
      };
    case TOGGLELOADING:
      let newState = { ...state, message: { ...state.message, loading: action.message?.loading } };
      return newState;
    case HIDEMODAL:
      return false;
    default:
      return state;
  }
}

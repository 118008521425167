import { CountryAction, UPDATE_COUNTRIES, ICountryProps } from './types';

const initialState: (ICountryProps | null)[] = [];

export function countryReducer(state = initialState, action: CountryAction) {
  switch (action.type) {
    case UPDATE_COUNTRIES: {
      const { countries } = action;
      return countries;
    }
    default:
      return state;
  }
}

import { SHOWMODAL, HIDEMODAL, ModalActionType } from './types';

const initialState = false as any;

export function loginReducer(state = initialState, action: ModalActionType) {
  switch (action.type) {
    case SHOWMODAL:
      return {
        ...action,
      };
    case HIDEMODAL:
      return false;
    default:
      return state;
  }
}

import { UserActionTypes, UPDATE_USER_INFO, REMOVE_USER_INFO, UPDATE_LOADED } from './types';

const initialState = {
  loaded: false,
  studentInfo: null,
} as any;

export function viewerReducer(state = initialState, action: UserActionTypes) {
  switch (action.type) {
    case UPDATE_USER_INFO: {
      const { viewer } = action;
      return { ...state, ...viewer, loaded: true };
    }
    case REMOVE_USER_INFO: {
      return initialState;
    }
    case UPDATE_LOADED: {
      return { ...state, loaded: true };
    }
    default:
      return state;
  }
}

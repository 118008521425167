export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';

export interface ICountryProps {
  id: string;
  name: string;
}

interface UpdateCountryAction {
  type: typeof UPDATE_COUNTRIES;
  countries: any[];
}
export type CountryAction = UpdateCountryAction;

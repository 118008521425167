import { UAContext } from '@quentin-sommer/react-useragent';
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import Button from '~components/button';
import Typography from '~components/typography';
import { Trans, useTranslation } from '~i18n';
import { LanguagesBg, LanguagesBubble } from '~svg-components';

interface IProps {
  open: boolean;
  language: string;
  onClose: () => void;
  onConfirm: () => void;
}

const Languages: any = {
  'en-gb': 'English',
  es: 'Spanish',
  ca: 'Catalan',
  de: 'German',
  'zh-cn': '中文',
};

const LanguageTips: React.FC<IProps> = ({ open, language, onClose, onConfirm }) => {
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const { t } = useTranslation(['student_portal']);

  const [hidden, sethidden] = useState(false);

  const handleClose = () => {
    sethidden(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };
  const handleConfirm = () => {
    sethidden(true);
    setTimeout(() => {
      onConfirm();
    }, 300);
  };
  return (
    <div className={classNames('languageWrapper', { rootOutMobile: hidden && isMobile })}>
      <div
        className={classNames('root', {
          rootIn: open && !isMobile,
          rootOut: hidden && !isMobile,
          rootInMobile: open && isMobile,
        })}
      >
        <div className="bubble">
          <LanguagesBg className="bubble__language-bubble-bg" />
          <LanguagesBubble className="bubble__language-bubble" />
        </div>
        <div className="content">
          <Typography variant="body2">
            <Trans ns="student_portal" i18nKey="language_tips">
              <span>{{ language: Languages[language] }}</span>
              <strong>{{ changeLanguage: Languages[language] }}</strong>
            </Trans>
          </Typography>
          <div className="actions">
            <Typography
              variant="body3"
              textDecoration="underline"
              cursor="pointer"
              style={{ marginRight: 24 }}
              onClick={() => handleClose()}
            >
              {t('no_thanks')}
            </Typography>
            <Button onClick={() => handleConfirm()}>{t('yes_please')}</Button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .root {
          width: 320px;
          height: 348px;
          position: fixed;
          right: 24px;
          bottom: -348px;
          z-index: 5000;
          background: var(--color-white);
          box-shadow: 0px 5px 20px 4px rgba(0, 0, 0, 0.1);
          border-radius: 14px;
          overflow: hidden;
          display: none;
        }
        .rootIn {
          display: flex;
          flex-direction: column;
          animation: languageAnimation 0.3s ease forwards;
          animation-delay: 3s;
        }
        .rootOut {
          animation: languageAnimationOut 0.3s ease forwards;
        }
        .bubble {
          width: 320px;
          height: 160px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          :global(&__language-bubble-bg) {
            position: absolute;
            top: 0;
            left: 0;
            z-index: ${-1};
            width: 320px;
            height: 160px;
          }
          :global(&__language-bubble) {
            width: 240px;
            height: 127px;
          }
        }
        .content {
          padding: 24px 24px 0 24px;
          flex: 1;
        }
        .actions {
          display: flex;
          margin-top: 20px;
          align-items: center;
          justify-content: flex-end;
        }
        @media screen and (max-width: 768px) {
          .languageWrapper {
            position: fixed;
            width: 100%;
            height: 100%;
            background: rgba(76, 76, 76, 0.6);
            top: 0;
            left: 0;
            z-index: 1000000;
          }
          .root {
            width: 80%;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            right: unset;
            bottom: unset;
            display: none;
            transform: translate(-50%, 50%);
          }
          .bubble {
            width: 100%;
            height: auto;
            :global(&__language-bubble-bg) {
              position: relative;
              width: 100%;
              height: auto;
            }
            :global(&__language-bubble) {
              position: absolute;
              width: 240px;
              height: 127px;
            }
          }
          .content {
            padding: 24px;
          }
          .actions {
            justify-content: center;
          }
          .rootInMobile {
            display: flex;
            flex-direction: column;
            animation: languageAnimationMobile 0.3s ease forwards;
          }
          .rootOutMobile {
            animation: languageAnimationMobileOut 0.3s ease forwards;
          }
          @keyframes languageAnimationMobile {
            from {
              transform: translate(-50%, 50%);
            }
            to {
              transform: translate(-50%, -50%);
            }
          }
          @keyframes languageAnimationMobileOut {
            from {
              top: 0;
            }
            to {
              top: 100%;
            }
          }
        }
        @keyframes languageAnimation {
          from {
            bottom: 0px;
          }
          to {
            bottom: 92px;
          }
        }
        @keyframes languageAnimationOut {
          from {
            bottom: 92px;
          }
          to {
            bottom: -348px;
          }
        }
      `}</style>
    </div>
  );
};

export default LanguageTips;
